/*
 * Angular 2 decorators and services
 */
import { Component, OnInit, ViewEncapsulation } from "@angular/core";

import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
} from "@angular/forms";

import { AppState } from "./app.service";
import { Router } from "@angular/router";
import { Sesion } from "./+seguridad/+iniciarSesion/class-sesion";
import { ControlInicioSessionService } from "./+seguridad/+controlInicioSession/controlInicioSession.service";
import { GlobalSharedService } from "./shared/globals";
import { SesionService } from "./router/sesionservice-router";
import { TipoUsuarioService } from "./+seguridad/+tipoUsuario/tipoUsuario.service";
import { TipoUsuario } from "./+seguridad/+tipoUsuario/class-tipoUsuario";
import { RegistroSession } from "./+seguridad/+controlInicioSession/class-controlInicioSession";
import { TranslateService } from "@ngx-translate/core";

import { CookieService } from "ngx-cookie-service";
import * as _ from "lodash";
import * as express from "express";

import * as Chart from "chart.js";

/*
 * App Component
 * Top Level Component
 */

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "app";
  public angularclassLogo = "assets/img/angularclass-avatar.png";
  public name = "Angular 2 Webpack Starter";
  public url = "https://twitter.com/AngularClass";
  public path: string = "seguridad/controlInicioSession";
  public visibilidadContador = "";
  public controlSessionPath: string = "";
  public contadorUsuarios: number = 0;
  public contadorProveedores: number = 0;
  public contador: number = 0;
  public tipoUsuarios: TipoUsuario[] = [];
  public tipoUsuarioSeleccionado: TipoUsuario;
  public lenguajes: { Id: string; Nombre: string }[];
  public lenguajeSeleccionado: { Id: string; Nombre: string };

  public chartLabel: string[];
  public chartType: string = "doughnut";
  public chartLabels: string[] = [];
  public chartData: any[] = [
    {
      data: ["35", "20"],
      backgroundColor: ["#fcf5ec", "#FFFF00"],
    },
  ];
  // public chartColors: any[] = [
  //   { backgroundColor: ["#000080", "#FFFF00", "#AFEEEE", "#FF7F50"] },
  // ];
  public chartColors: any[] = [
    { backgroundColor: ["#fcf5ec", "#FFFF00", "#AFEEEE", "#FF7F50"] },
  ];
  public chartOptions: any = {
    legend: {
      display: false,
    },
    elements: {
      center: {
        text: "Hello",
        fontColor: "#000",
        fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        fontSize: 24,
        fontStyle: "normal",
      },
    },
    cutoutPercentage: 70,
    responsive: true,
  };

  constructor(
    public appState: AppState,
    private router: Router,
    private sesionService: SesionService,
    private c: CookieService,
    public translate: TranslateService,
    public controlInicioSessionService: ControlInicioSessionService,
    public tipoUsuarioService: TipoUsuarioService,
    public globalSharedService: GlobalSharedService
  ) {
    this.translate.setDefaultLang("es");
    this.translate.use("es");

    sesionService.sesionIniciada$.subscribe((s) => {
      this.sesionIniciada(s);
    });
    setInterval(() => {
      this.actualizarContador();
    }, 120000);
  }

  sesionIniciada(_sesion: Sesion): void {
    let p = this.path;
    let opcion = _.filter(_sesion.Opciones, function (item) {
      return item.Accion == p;
    });
    if (opcion.length > 0) {
      this.controlSessionPath = this.path;
    }
    this.actualizarContador();
  }

  public textoContador: string;

  public ngOnInit() {
    this.contadorUsuarios = 1;
    this.contadorProveedores = 1;
    this.textoContador = "Hola";
    let x = "55";
    this.getTipoUsuarios();
    this.tipoUsuarioSeleccionado = new TipoUsuario();

    this.globalSharedService.sesion = new Sesion();
    this.globalSharedService.sesion.SessionIniciada = false;
    this.lenguajes = [];
    this.lenguajes = [
      { Id: "es", Nombre: "Español" },
      { Id: "en", Nombre: "English" },
    ];
    this.lenguajeSeleccionado = this.lenguajes[0];
    this.switchLanguage(this.lenguajeSeleccionado.Id);
  }

  public switchLanguage(lenguaje: string) {
    if (lenguaje != "" || lenguaje != undefined) {
      this.translate.use(lenguaje);
    }
    this.actualizarContador();
  }
  public actualizarContador() {
    this.visibilidadContador = "";
    let data: number[] = [];
    let labels: string[] = [];
    let fechaInicial = new Date(new Date().setHours(0, 0, 0));
    let fechaFinal = new Date(new Date().setHours(23, 59, 59));

    let clone = JSON.parse(JSON.stringify(this.chartData));
    let clone2 = JSON.parse(JSON.stringify(this.chartLabels));

    clone2 = [];
    this.tipoUsuarioService.getAll().subscribe((tu) => {
      let usuarios: TipoUsuario[] = tu;
      this.controlInicioSessionService
        .getTotalVisitas(fechaInicial, fechaFinal)
        .subscribe((cis) => {
          usuarios.forEach((us) => {
            let registrosGrupo: any[] = cis;
            let c = _.sumBy(
              _.filter(registrosGrupo, function (item) {
                return item.TipoUsuarioID == us.Id;
              }),
              function (o) {
                return o.Total;
              }
            );
            this.translate.get(us.Descripcion).subscribe((value) => {
              // value is our translated string
              labels.push(value);
            });

            data.push(c);
          });
          clone[0].data = data;
          clone2 = labels;
          this.visibilidadContador = "mostrar";
          this.chartData = clone;
          this.chartLabels = clone2;
          clone = [];
          clone2 = [];
          this.contador = _.sum(data);
          this.beforeDrawChart(this.contador.toString());
        });
    });
  }

  public beforeDrawChart(xx: string) {
    Chart.pluginService.register({
      beforeDraw: function (chart) {
        if (chart.config.options.elements.center) {
          let width = chart.chart.width,
            height = chart.chart.height,
            ctx = chart.chart.ctx;

          let fontSize = (height / 114).toFixed(2);
          ctx.font = fontSize + "em sans-serif";
          ctx.textBaseline = "middle";
          ctx.fillStyle = "#FFFFFF";
          let x = this.textoContador;

          let text = xx,
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2;
          ctx.clearRect(0, 0, width, height);
          ctx.fillText(text, textX, textY);
          ctx.restore();
          ctx.save();
        }
      },
    });
  }

  public getModulos() {
    if (
      this.globalSharedService.sesion &&
      this.globalSharedService.sesion.SessionIniciada
    ) {
      let ops = _.uniqBy(this.globalSharedService.sesion.Opciones, "ModuloId");
      for (let i in ops) {
        if (ops[i].ModuloId == "Proveedores") {
          ops[i].ModuloIcon = "business_center";
        }
        if (ops[i].ModuloId == "Recepción de Docs") {
          ops[i].ModuloIcon = "folder";
        }
        if (ops[i].ModuloId == "Seguridad") {
          ops[i].ModuloIcon = "vpn_key";
        }
        if (ops[i].ModuloId == "Métricas") {
          ops[i].ModuloIcon = "insert_chart";
        }
        if (ops[i].ModuloId == "Sla MAT") {
          ops[i].ModuloIcon = "insert_chart";
        }
        if (ops[i].ModuloId == "Clientes") {
          ops[i].ModuloIcon = "people";
        }
      }
      return ops;
    }
  }

  public getTipoUsuarios() {
    this.tipoUsuarioService.getAll().subscribe((tu) => {
      this.tipoUsuarios = tu;
    });
  }

  public getTipoUsuario(tipo: string) {
    this.visibilidadContador = "";
    this.globalSharedService.tipoUsuario = tipo;
    if (tipo == "UsuarioGTM") {
      this.visibilidadContador = "mostrar";
    }
  }

  public getOpciones(moduloId: string) {
    if (this.globalSharedService.sesion) {
      let opciones = this.globalSharedService.sesion.Opciones;
      return _.filter(opciones, function (o) {
        return o.ModuloId == moduloId;
      });
    }
  }

  public cerrarSesion(): void {
    let idSession = this.globalSharedService.sesion.SessionId;

    this.globalSharedService.sesion = null;
    // this.c.delete("sesion");
    localStorage.removeItem("sesion");
    this.router.navigate([""]);
    this.controlInicioSessionService
      .getById(idSession.toString())
      .subscribe((m) => {
        if (m) {
          let session: RegistroSession = m;
          session.FechaFin = new Date();
          this.controlInicioSessionService.update(session).subscribe((m) => {
            if (m) {
              let idSession = "";
            }
          });
        }
      });
  }
}
