import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { A11yModule } from '@angular/cdk/a11y';
// import { CompatibilityModule } from '@angular/material';
import { MatAutocompleteModule } from '@angular/material'; 
import { MatButtonToggleModule } from '@angular/material';
import { MatButtonModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material';
import { MatDialogModule } from '@angular/material';  
import { MatListModule } from '@angular/material';  
import { MatGridListModule } from '@angular/material';
import { MatCardModule } from '@angular/material';  
import { MatChipsModule } from '@angular/material';  
import { MatIconModule } from '@angular/material';  
import { MatInputModule } from '@angular/material'; 
import { MatMenuModule } from '@angular/material';  
import { MatProgressSpinnerModule } from '@angular/material';
import { MatProgressBarModule } from '@angular/material'; 
import { MatRadioModule } from '@angular/material'; 
import { MatRippleModule } from '@angular/material';
import { MatSelectModule } from '@angular/material';  
import { MatSlideToggleModule } from '@angular/material';
import { MatSliderModule } from '@angular/material';  
import { MatSidenavModule } from '@angular/material'; 
import { MatSnackBarModule } from '@angular/material';
import { MatTabsModule } from '@angular/material';  
import { MatToolbarModule } from '@angular/material';  
import { MatTooltipModule } from '@angular/material';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkTableModule } from '@angular/cdk/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material'; 
// import { MatNativeDatetimeModule } from '@angular/material-moment-adapter';
// import { MatMomentDatetimeModule } from '@mat-datetimepicker/moment';
import { MatMomentDatetimeModule } from '@mat-datetimepicker/moment';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

// MatNativeDatetimeModule, MatMomentDatetimeModule



// import { PlatformModule } from '@angular/material'; 
// import { StyleModule } from '@angular/cdk/overlay';

const MATERIAL_MODULES = [
  CommonModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatChipsModule,
  MatCheckboxModule,
  MatGridListModule,
  MatInputModule,
  MatListModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatTabsModule,
  MatToolbarModule,
  MatFormFieldModule,
  MatDatepickerModule,

  // These modules include providers.
  A11yModule,
  // CompatibilityModule,
  MatButtonToggleModule,
  MatDialogModule,
  MatIconModule,
  MatMenuModule,
  MatRadioModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTooltipModule,
  OverlayModule,
  CdkTableModule,

   
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatMenuModule,
    
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  
    MatNativeDateModule,
    MatMomentDatetimeModule,
    MatDatetimepickerModule,
  // otro module
  OwlDateTimeModule,
  OwlNativeDateTimeModule

  // PlatformModule,
  // StyleModule
];


@NgModule({
  imports: MATERIAL_MODULES,
  exports: MATERIAL_MODULES,
  declarations: []
})
export class MyMaterialModule { }