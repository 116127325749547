import { Injectable } from "@angular/core";
import { Subject } from "rxjs/Subject";

import { Sesion } from "../+seguridad/+iniciarSesion/class-sesion";

@Injectable()
export class SesionService {
  // Observable string sources
  private sesionIniciadaSource = new Subject<Sesion>();
  // Observable string streams
  sesionIniciada$ = this.sesionIniciadaSource.asObservable();
  // Service message commands
  iniciarSesion(s: Sesion) {
    this.sesionIniciadaSource.next(s);
  }
}
