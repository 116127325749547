'use strict';

import { PaisesOficina } from '../../+proveedores/+paisesOficina/class-paisesOficina';
import { Opcion } from '../../+seguridad/+opcion/class-opcion';
import { RolUsuario } from '../../+seguridad/+rolUsuario/class-rolUsuario';

export class Sesion {
    public UsuarioID: string;
    public Nombre: string;
    public Correo: string;
    public Rol: string;
    public Estado: string;
    public PaisID: string;
    public NombrePais: string;
    public Moneda: string;
    public SessionId: string;
    public Oficina: PaisesOficina;
    public SessionIniciada: Boolean;
    public RolUsuario: RolUsuario[];
    public Opciones: Opcion[];
}
