import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '',  loadChildren: './+inicio/+carrusel/carrusel.module#CarruselModule'},
  { path: 'carrusel',  loadChildren: './+inicio/+carrusel/carrusel.module#CarruselModule'},
  { path: 'contacto',  loadChildren: './+inicio/+contacto/contacto.module#ContactoModule'},

  // modulo Seguridad
  { path: 'seguridad/iniciarSesion',  loadChildren: './+seguridad/+iniciarSesion/iniciarSesion.module#IniciarSesionModule'},
  { path: 'seguridad/usuarios',  loadChildren: './+seguridad/+usuario/usuario.module#UsuarioModule'},
  { path: 'seguridad/roles',  loadChildren: './+seguridad/+rol/rol.module#RolModule'},
  { path: 'seguridad/obtenerClaveProveedor',  loadChildren: './+seguridad/+obtenerClaveProveedor/obtenerClaveProveedor.module#ObtenerClaveProveedorModule'},
  { path: 'seguridad/maestroCorreosSeguridad',  loadChildren: './+seguridad/+maestroCorreoSeguridad/maestroCorreoSeguridad.module#MaestroCorreoSeguridadModule'},
  { path: 'seguridad/controlInicioSession',  loadChildren: './+seguridad/+controlInicioSession/controlInicioSession.module#ControlInicioSessionModule'},
  { path: 'seguridad/consultaLogServicios',  loadChildren: './+seguridad/+logError/logError.module#LogErrorModule'},
  
  { path: 'proveedores/bancos',  loadChildren: './+proveedores/+banco/banco.module#BancoModule'},
  { path: 'proveedores/categorias',  loadChildren: './+proveedores/+categoria/categoria.module#CategoriaModule'},
  { path: 'proveedores/metodopagos',  loadChildren: './+proveedores/+metodoPago/metodoPago.module#MetodoPagoModule'},
  { path: 'proveedores/metodoentregas',  loadChildren: './+proveedores/+metodoEntrega/metodoEntrega.module#MetodoEntregaModule'},
  { path: 'proveedores/requisitos',  loadChildren: './+proveedores/+requisito/requisito.module#RequisitoModule'},
  { path: 'proveedores/paisesOficinas',  loadChildren: './+proveedores/+paisesOficina/paisesOficina.module#PaisesOficinaModule'},
  { path: 'proveedores/proveedores',  loadChildren: './+proveedores/+proveedor/proveedor.module#ProveedorModule'},
  { path: 'proveedores/recepcionfactura',  loadChildren: './+proveedores/+recepcionFactura/recepcionFactura.module#RecepcionFacturaModule'},
  { path: 'proveedores/documentosentregados',  loadChildren: './+proveedores/+documentosEntregados/documentosEntregados.module#DocumentosEntregadosModule'},
  { path: 'proveedores/estadoCuenta',  loadChildren: './+proveedores/+estadoCuenta/estadoCuenta.module#EstadoCuentaModule'},
  { path: 'proveedores/notificacionPagos',  loadChildren: './+proveedores/+notificacionPago/notificacionPago.module#NotificacionPagoModule'},
  { path: 'proveedores/reimpresionFacturaTicket',  loadChildren: './+proveedores/+reimpresionFacturaTicket/reimpresionFacturaTicket.module#ReimpresionFacturaTicketModule'},
  { path: 'proveedores/recepcionfacturaAnular',  loadChildren: './+proveedores/+recepcionFacturaAnular/recepcionFacturaAnular.module#RecepcionFacturaAnularModule'},
  { path: 'metricas/metricas',  loadChildren: './+metricas/+metricas/metricas.module#MetricasModule'},

  { path: 'sla/incident',  loadChildren:   './+sla/+incident#IncidentModule'},
  { path: 'sla/reporteSlaCategoria',  loadChildren: './+sla/+reporteSlaCategoria#ReporteSlaCategoriaModule'},
  { path: 'sla/reporteSlaNivelesUsuarios',  loadChildren: './+sla/+reporteSlaNivelesUsuarios#ReporteSlaNivelesUsuariosModule'},

  { path: 'clientes/maestroCorreo',  loadChildren:   './+clientes/+maestroCorreo/maestroCorreo.module#MaestroCorreoModule'},
  { path: 'clientes/estadoCuenta',  loadChildren:   './+clientes/+estadoCuenta/estadoCuenta.module#EstadoCuentaModule'},
  { path: 'clientes/reciboPago',  loadChildren:   './+clientes/+reciboPago#ReciboPagoModule'},
  { path: 'clientes/generarClave',  loadChildren:   './+clientes/+generarClave/generarClave.module#GenerarClaveModule'},
  { path: 'clientes/enviarCorreoCliente',  loadChildren:   './+clientes/+enviarCorreoCliente/enviarCorreoCliente.module#EnviarCorreoClienteModule'},
  { path: 'clientes/conciliacionesCliente',  loadChildren:   './+clientes/+conciliacionEstadoCuenta/conciliacionEstadoCuenta.module#ConciliacionEstadoCuentaModule'},
  { path: 'clientes/consultaConciliacionesCliente',  loadChildren:   './+clientes/+consultaConciliaciones/consultaConciliaciones.module#ConsultaConciliacionesModule'},
  { path: 'clientes/cierreCaja',  loadChildren:   './+clientes/+cierreCaja/cierreCaja.module#CierreCajaModule'},
  { path: 'clientes/reciboPagoChequePostAplicar',  loadChildren:   './+clientes/+reciboPagoChequePostAplicar/reciboPagoChequePostAplicar.module#ReciboPagoChequePostAplicarModule'},
  
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
